<template>
  <div class="home full">
    <div class="left-panel">
      <SheBeiLieBiao />
    </div>
    <div class="right-panel">
      <ShiShiBaoJing />
      <LiShiBaoJing />
    </div>
  </div>
</template>

<script>
import SheBeiLieBiao from '../components/monitor/manager/SheBeiLieBiao.vue'
import ShiShiBaoJing from '../components/monitor/manager/ShiShiBaoJing.vue'
import LiShiBaoJing from '../components/monitor/manager/LiShiBaoJing.vue'
export default {
  components: {
    SheBeiLieBiao,
    ShiShiBaoJing,
    LiShiBaoJing
  }
}
</script>

<style lang="less" scoped>
.home {
  pointer-events: none;
}
</style>
