<template>
  <div class="overview-box">
    <th-sub-title text="事件趋势" icon="icon_gongnengjiance" />
    <div class="chart-box rel">
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    updateChart() {
      let xData = []
      let yData = []
      let sum = 0
      for (let i = 0; i < 12; i++) {
        xData.push(i + 1)
        let v = Number((Math.random() * 100 + 400).toFixed(2))
        yData.push(v)
        sum += v
      }
      this.total = Math.round(sum)
      this.range = Number((Math.random() * 40 - 20).toFixed(0))
      this.option.xAxis.data = xData
      this.option.series[0].data = yData
      this.option.series[0].itemStyle.color = this.colors[this.type]
      this.option.series[0].name = this.type
      this.myChart.setOption(this.option)
    }
  },
  mounted() {
    this.myChart = echarts.init(this.$el.querySelector('.chart-box'))
    this.updateChart()
  },
  data() {
    return {
      total: 0,
      range: 0,
      labels: {
        耗电量: '年度累计耗电量（kWh）',
        耗水量: '年度累计耗水量（t）',
        用气量: '年度累计用气量（m³）'
      },
      colors: {
        耗电量: 'rgba(14, 156, 255, 1)',
        耗水量: 'rgba(55, 182, 139, 1)',
        用气量: 'rgba(51, 103, 255, 1)'
      },
      option: {
        tooltip: {
          show: true,
          trigger: 'axis'
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
          axisLabel: {
            color: '#ffffff'
          },
          axisLine: {
            show: false
          },
          axisTick: {
            show: false
          }
        },
        yAxis: {
          type: 'value',
          max: 600,
          axisLabel: {
            color: '#ffffff'
          },
          splitLine: {
            lineStyle: {
              type: 'dashed',
              color: 'rgba(204, 204, 204, 0.3)'
            }
          }
        },
        grid: {
          top: 20,
          bottom: 20,
          right: 10
        },
        series: [
          {
            name: '入园',
            data: [820, 932, 901, 934, 1290, 1330, 1320],
            type: 'line',
            smooth: true,
            symbol: 'circle',
            itemStyle: {
              color: '#36cd9f'
            },
            areaStyle: {
              opacity: 0.2
            }
          }
        ]
      }
    }
  }
}
</script>

<style lang="less" scoped>
.overview-box {
  text-align: center;
}
.chart-box {
  width: 350px;
  margin: 10px auto;
  height: 210px;
}
</style>
