export default [
  {"时间":"","设施":"环境监测设施","预警":"网络掉线","处理情况":"已处理"},
  {"时间":"","设施":"环境信息大屏","预警":"屏幕报错","处理情况":"已处理"},
  {"时间":"","设施":"智慧运动设施","预警":"网络掉线","处理情况":"已处理"},
  {"时间":"","设施":"智慧广场舞","预警":"屏幕报错","处理情况":"已处理"},
  {"时间":"","设施":"智慧运动设施","预警":"电源预警","处理情况":"已处理"},
  {"时间":"","设施":"智慧运动设施","预警":"屏幕报错","处理情况":"已处理"},
  {"时间":"","设施":"智慧广场舞","预警":"使用报错","处理情况":"已处理"},
  {"时间":"","设施":"智慧广场舞","预警":"网络掉线","处理情况":"已处理"},
  {"时间":"","设施":"环境监测设施","预警":"网络掉线","处理情况":"已处理"},
  {"时间":"","设施":"环境信息大屏","预警":"屏幕报错","处理情况":"已处理"},
  {"时间":"","设施":"智慧运动设施","预警":"网络掉线","处理情况":"已处理"},
  {"时间":"","设施":"智慧广场舞","预警":"屏幕报错","处理情况":"已处理"},
  {"时间":"","设施":"智慧运动设施","预警":"电源预警","处理情况":"已处理"},
  {"时间":"","设施":"智慧运动设施","预警":"屏幕报错","处理情况":"已处理"},
  {"时间":"","设施":"智慧广场舞","预警":"使用报错","处理情况":"已处理"},
  {"时间":"","设施":"智慧广场舞","预警":"网络掉线","处理情况":"已处理"},
  {"时间":"","设施":"环境信息大屏","预警":"屏幕报错","处理情况":"已处理"},
  {"时间":"","设施":"智慧运动设施","预警":"使用报错","处理情况":"已处理"},
  {"时间":"","设施":"智慧广场舞","预警":"网络掉线","处理情况":"已处理"},
  {"时间":"","设施":"智慧运动设施","预警":"网络掉线","处理情况":"已处理"}
  ]