import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import viewUI from 'view-design'
import 'mapbox-gl/dist/mapbox-gl.css'
import 'view-design/dist/styles/iview.css'
import '@/assets/style.css'


import Layout from '@/components/share/Layout'
import Icon from '@/components/share/Icon'
import SubTitle from '@/components/share/SubTitle'
import Countup from '@/components/share/CountUp'
import IndicatorH from '@/components/share/IndicatorH'
import IndicatorV from '@/components/share/IndicatorV'
import Notice from '@/components/share/Notice'
import RadioGroup from '@/components/share/RadioGroup'
import TextIndicator from '@/components/share/TextIndicator'

Vue.config.productionTip = false
Vue.use(viewUI)

Vue.component('th-layout', Layout)
Vue.component('th-icon', Icon)
Vue.component('th-sub-title', SubTitle)
Vue.component('th-countup', Countup)
Vue.component('th-indicator-h', IndicatorH)
Vue.component('th-indicator-v', IndicatorV)
Vue.component('th-notice', Notice)
Vue.component('th-radio-group', RadioGroup)
Vue.component('th-text-indicator', TextIndicator)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
