<template>
  <div class="overview-box">
    <th-sub-title text="历史报警" icon="icon_gjxx" />
    <div class="result-panel">
      <div class="head">
        <table>
          <tr>
            <th>事件类型</th>
            <th>发生位置</th>
            <th>预警设备</th>
            <th>发生时间</th>
          </tr>
          <tr></tr>
        </table>
      </div>
      <div class="content">
        <table>
          <tr v-for="(item, index) in list" :key="index">
            <td>{{item['事件类型']}}</td>
            <td>{{item['发生位置']}}</td>
            <td>{{item['预警设备']}}</td>
            <td>{{item['发生时间']}}</td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import moment from 'moment'
export default {
  methods: {
    
  },
  mounted() {
    let ref = moment().subtract(1, 'days').hour(6)
    axios.get('./data/history_events.json').then(res => {
      this.list = res.data.map(item => {
        let t = Math.round(Math.random() * 120 + 480)
        ref = ref.subtract(t, 'minutes')
        item['发生时间'] = ref.format('YYYY-MM-DD HH:mm:ss')
        return item
      })
    })
  },
  data() {
    return {
      list: []
    }
  }
}
</script>

<style lang="less" scoped>
.overview-box {
  text-align: center;
}
.result-panel{
  margin-top: 25px;
  .head, .content{
    width: 350px;
    margin: 0 auto;
  }
  .head{
    height: 32px;
    color: #01E99B;
    line-height: 32px;
    background: rgba(0, 255, 169, 0.25);
  }
  .content{
    background: rgba(0, 232, 253, 0.1);
    font-size: 16px;
    line-height: 28px;
    height: 600px;
    overflow: auto;
    .no-data{
      padding: 10px;
    }
    .img{
      width: 143px;
      height: 80px;
      margin: 0 auto;
      background-image: url(~@/assets/images/pic_kongzhuangtai.png);
    }
    .label{
      color: #9BB8DA;
    }
    .tips{
      color: #FF6565;
    }
  }
  table{
    width: calc(100% - 10px);
  }
  td, th{
    &:last-child{
      width: 100px;
    }
  }
}
</style>
