<template>
  <div class="overview-box">
    <th-sub-title text="智慧场景运行状态" icon="icon_gongnengjiance" />
    <div class="table">
      <div class="head">
        <table>
          <tr>
            <th>智慧场景</th>
            <th>运行状态</th>
          </tr>
        </table>
      </div>
      <div class="content">
        <table>
          <tr>
            <td>智慧广场舞</td>
            <td>良好</td>
          </tr>
          <tr>
            <td>智慧环境</td>
            <td>良好</td>
          </tr>
          <tr>
            <td>智慧运动</td>
            <td>良好</td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    
  },
  mounted() {
  },
  data() {
    return {
    }
  }
}
</script>

<style lang="less" scoped>
.overview-box {
  text-align: center;
}
.table{
  width: calc(100% - 40px);
  margin: 20px auto;
}
.head{
  height: 40px;
  color: #01E99B;
  line-height: 40px;
  font-size: 16px;
  background: rgba(0, 255, 169, 0.25);
}
.content{
  background: rgba(0, 232, 253, 0.1);
  line-height: 40px;
  max-height: 140px;
  overflow: auto;
}
table{
  width: calc(100% - 10px);
  th, td{
    width: 50%;
  }
}
tr{
  height: 32px;
}
</style>
