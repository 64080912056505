<template>
  <div class="overview-box">
    <th-sub-title text="预警分类列表" icon="icon_peitaosheshi" />
    <div class="lt">
      <th-icon name="icon_ren" width="22" height="22"/>
      <span>公共安全</span>
    </div>
    <EventTable :info="list1"/>
    <div class="lt">
      <th-icon name="icon_ren" width="22" height="22"/>
      <span>公共服务</span>
    </div>
    <EventTable :info="list2"/>
  </div>
</template>

<script>
import EventTable from './EventTable.vue'
import moment from 'moment'
import eventList1 from '../../../constants/events_list'
import eventList2 from '../../../constants/events_list2'
export default {
  components: {
    EventTable
  },
  methods: {
    getDate(time) {
      let arr = time.split(':')
      return moment().hour(arr[0]).minute(arr[1]).second(arr[2])
    }
  },
  mounted() {
    window.moment = moment

    let list1 = eventList1.filter(item => this.getDate(item['事件发生时间']).isBefore(moment())).sort((a, b) => {
      return this.getDate(b['事件发生时间']).isBefore(this.getDate(a['事件发生时间'])) ? -1 : 1
    })
    let list2 = eventList2.filter(item => this.getDate(item['事件发生时间']).isBefore(moment())).sort((a, b) => {
      return this.getDate(b['事件发生时间']).isBefore(this.getDate(a['事件发生时间'])) ? -1 : 1
    })
    console.log(list1)
    this.list1 = list1
    this.list2 = list2
  },
  data() {
    return {
      list1: [],
      list2: []
    }
  }
}
</script>

<style lang="less" scoped>
.overview-box {
  text-align: center;
}
.lt {
  width: 370px;
  height: 32px;
  margin: 10px auto;
  font-size: 16px;
  color: #4BF5F1;
  line-height: 32px;
  background: linear-gradient(
    to right,
    rgba(55, 182, 139, 0),
    rgba(0, 232, 253, 0.15),
    rgba(55, 182, 139, 0)
  );
  .icon{
    position: relative;
    top: -2px;
  }
  span {
    margin: 0 10px;
  }
}
</style>
