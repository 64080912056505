<template>
  <div class="indicator">
    <div class="label-box">
      <span class="label">{{ label }}</span>
    </div>
    <div class="value-box">
      <th-countup class="v-value" :value="value" color="#4bf5f1" :size="24" />
      <span class="unit">{{ unit }}</span>
    </div>
    <div class="meta-box">
      {{meta}}
    </div>
  </div>
</template>

<script>
export default {
  props: ["label", "value", "unit", "meta"],
};
</script>

<style lang="less" scoped>
.indicator {
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  font-size: 20px;
  width: 160px;
  line-height: 40px;
  background: rgba(4, 211, 205, 0.05);
  border-radius: 10px;
  margin: 20px;
  border: 1px solid #04d3cd;
  .label-box {
    width: 100%;
    border-bottom: 2px dotted #0d8982;
  }
  .value-box{
    line-height: 50px;
  }
  .label {
    padding: 10px;
    font-size: 20px;
    font-weight: 400;
    color: #ebfffe;
  }
  .v-value {
    font-family: DIN;
  }
  .unit {
    font-size: 16px;
    font-weight: 400;
    color: #ebfffe;
  }
  .meta-box{
    color: #ff4d4d;
    font-size: 16px;
    line-height: 30px;
    height: 30px;
    border-top: 2px dotted #0d8982;
  }
}
</style>