<template>
  <div class="home full">
    <div class="left-panel">
      <Location />
      <ServiceChart />
      <FacilityChart />
      <FlowChart />
    </div>
    <div class="right-panel">
      <Environment />
      <Video />
      <!-- <EventsChart /> -->
    </div>
    <ServiceIndicator />
    <Running />
  </div>
</template>

<script>
import Location from '../components/home/Location.vue'
import ServiceChart from '../components/home/ServiceChart.vue'
import FacilityChart from '../components/home/FacilityChart.vue'
import FlowChart from '../components/home/FlowChart.vue'
import Video from '../components/home/Video.vue'
import EventsChart from '../components/home/EventsChart.vue'
import Environment from '@/components/home/Environment.vue'
import Running from '../components/home/Running.vue'
import ServiceIndicator from '../components/home/ServiceIndicator.vue'

export default {
  name: 'Home',
  components: {
    Location,
    ServiceChart,
    FacilityChart,
    FlowChart,
    Video,
    Environment,
    EventsChart,
    Running,
    ServiceIndicator
  }
}
</script>

<style lang="less" scoped>
.home {
  pointer-events: none;
}
</style>
