<template>
  <div class="layers">
    <CheckboxGroup :value="selectedLayerName" @on-change="layerChange">
      <div v-for="item in list" :key="item.name">
        <Checkbox :label="item.name" size="large"></Checkbox>
      </div>
    </CheckboxGroup>
  </div>
</template>

<script>
import {mapState} from 'vuex'
export default {
  methods: {
    layerChange(names) {
      let layers = this.list.filter(item => names.indexOf(item.name)!==-1)
      this.$store.commit('patch_layers', layers)
    }
  },
  mounted() {

  },
  data() {
    return {
      list: [
        {
          name: 'POI',
          layers: ['poi']
        },
        {
          name: '公交站点及线路',
          layers: ['社区交通']
        },
        {
          name: '社区交通流量',
          layers: []
        },
        {
          name: '社区周边设施',
          layers: ['周边设施']
        },
        {
          name: '社区设施',
          layers: ['社区设施']
        },
        {
          name: '社区人口密度',
          layers: ['人口密度图']
        }
      ]
    }
  },
  computed: {
    ...mapState({
      selectedLayers: state => state.selectedLayers
    }),
    selectedLayerName() {
      return this.selectedLayers.map(item => item.name)
    }
  }
}
</script>

<style lang="less" scoped>
.layers{
  position: absolute;
  left: 450px;
  top: 100px;
  font-size: 16px;
  background: rgba(32, 47, 73, 0.4);
  padding: 20px;
  line-height: 30px;
  border: 1px solid #4bf5f1;
  border-radius: 5px;
  box-shadow: 0 0 10px #4bf5f1 inset;
}
</style>
