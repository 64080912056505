<template>
  <div class="overview-box">
    <th-sub-title text="预警事件类型" icon="icon_yuanquzhaoshang" />
    <div class="chart"></div>
  </div>
</template>

<script>
import _ from 'lodash'
export default {
  methods: {
    updateChart() {
      this.pieOption.series[0].data = [{name: '高频陌生人', value: 78}, {name: '人员摔倒', value:42}, {name: '周界入侵', value:30}, {name: '电瓶车进电梯', value:19}, {name: '离岗事件', value:12}]
      this.chart.setOption(this.pieOption)
    }
  },
  mounted() {
    this.chart = echarts.init(this.$el.querySelector('.chart'))
    this.updateChart()
  },
  data() {
    return {
      pieOption: {
        title: {
          text: '',
          left: 'center',
          textStyle: {
            color: '#F6A416',
            fontSize: 14
          }
        },
        tooltip: {
          trigger: 'item'
        },
        legend: {
          show: false
        },
        series: [
          {
            name: '',
            type: 'pie',
            radius: ['30%', '50%'],
            color: ['#297eff', '#36cd9f', '#ca29ff', '#29ebff', '#e8ff29'],
            avoidLabelOverlap: true,
            label: {
              show: true,
              formatter: '{b}\n{d}%',
              color: '#ffffff',
              lineHeight: 18
            },
            labelLine: {
              show: true
            },
            data: []
          }
        ]
      }
    }
  }
}
</script>

<style lang="less" scoped>
.overview-box {
  text-align: center;
}
.chart{
  width: 350px;
  height: 220px;
  margin: 5px auto;
}
</style>
