<template>
  <span class="count-up" :style="`color:${color || '#f59106'};font-size:${size || 20}px;`">0</span>
</template>

<script>
import {CountUp} from 'countup.js'
export default {
  props: ['start', 'value', 'color', 'size', 'decimals', 'duration', 'separator'],
  methods: {
    initValue(el, start, end) {
      let countUp = new CountUp(el, end || 0, {
        startVal: start || 0, 
        decimalPlaces: this.decimals || 0, 
        duration: this.duration || 2, 
        useEasing: false,
        useGrouping: this.separator
      })
      if (!countUp.error) {
        countUp.start();
      } else {
        console.error(countUp.error);
      }
      return countUp
    }
  },
  data() {
    return {
      instance: null
    }
  },
  mounted() {
    let dom = this.$el
    this.instance = this.initValue(dom, this.start || 0, this.value)
  },
  watch: {
    value: function() {
      this.instance.update(this.value)
    }
  }
}
</script>

<style scoped>
</style>
