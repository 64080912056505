<template>
  <div class="login">
    <div class="title">
      国家重点专项课题成果转化示范应用——融创奥城智慧社区
    </div>
    <div class="panel">
      <div class="left inline">
        <div class="logo"></div>
        <div class="name">融创奥城</div>
      </div>
      <div class="right inline">
        <Form :model="options" :label-width="80">
          <FormItem label="用户名">
            <Input v-model="options.username" size="large" placeholder="输入用户名" style="width: 300px;"></Input>
          </FormItem>
          <FormItem label="密码">
            <Input v-model="options.passwd" size="large" type="password" placeholder="输入密码" style="width: 300px;"></Input>
          </FormItem>
          <FormItem label="">
            <Button type="primary" size="large" style="width: 300px;" @click="login">登录</Button>
          </FormItem>
        </Form>
        
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import Cookie from 'js-cookie'
import md5 from 'md5'
export default {
  methods: {
    login() {
      let {username, passwd} = this.options
      axios.post(`${API}/users/login`, {username, password: md5(passwd)}).then(res => {
        Cookie.set('username',username)
        this.$router.push({
          name: '用户管理'
        })
      })
    }
  },
  data() {
    return {
      options: {
        username: '',
        passwd: ''
      }
    }
  }
}
</script>

<style lang="less" scoped>
.login{
  position: absolute;
  width: 100%;
  height: 100%;
  background-image: url(~@/assets/images/login_bg.jpeg);
  background-size: 100% 100%;
  .title{
    font-size: 48px;
    text-align: left;
    padding: 20px 40px;
  }
  .panel{
    width: 900px;
    height: 400px;
    background-color: #ffffff;
    border-radius: 20px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    box-shadow: 0 0 40px #363636;
    .left{
      width: 400px;
      height: 300px;
      padding: 50px 0;
      margin: 50px 0;
      border-right: 1px solid #c9c9c9;
      .name{
        font-size: 38px;
        color: #1791a5;
        margin: 20px 0;
        letter-spacing: 0.5em;
      }
    }
    .logo{
      width: 300px;
      height: 100px;
      margin: 0 auto;
      background-image: url(~@/assets/images/logo.png);
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
    }
    .right{
      width: 500px;
      text-align: left;
    }
  }
}
</style>