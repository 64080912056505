<template>
  <div id="app">
    <router-view v-if="page==='登录' || page==='用户管理'"/>
    <th-layout mode="height" v-else>
      <router-view />
    </th-layout>
  </div>
</template>

<script>
export default {
  methods: {
    

  },
  computed: {
    page() {
      return this.$route.name
    }
  }
}
</script>

<style lang="less">
#app {
  font-family: SourceHanSansCN, Microsoft YaHei, Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #ffffff;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
</style>
