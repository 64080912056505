<template>
  <div class="overview-box">
    <th-sub-title text="催办率" icon="icon_gongnengjiance" />
    <div class="chart-box rel">
    </div>
    <p>（以上三项数据来源于服务终端，每两周更新最新数据）</p>
  </div>
</template>

<script>
export default {
  methods: {
    updateChart() {
      this.myChart.setOption(this.option)
    }
  },
  mounted() {
    this.myChart = echarts.init(this.$el.querySelector('.chart-box'))
    this.updateChart()
  },
  data() {
    return {
      total: 0,
      range: 0,
      option: {
        tooltip: {
          show: true,
          trigger: 'axis'
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: ["2021/10/1","2021/10/15","2021/10/29","2021/11/12","2021/11/26","2021/12/10","2021/12/24","2022/1/7","2022/1/21","2022/2/4","2022/2/18","2022/3/4","2022/3/18"],
          axisLabel: {
            color: '#ffffff'
          },
          axisLine: {
            show: false
          },
          axisTick: {
            show: false
          }
        },
        yAxis: {
          type: 'value',
          max: 100,
          axisLabel: {
            color: '#ffffff'
          },
          splitLine: {
            lineStyle: {
              type: 'dashed',
              color: 'rgba(204, 204, 204, 0.3)'
            }
          }
        },
        grid: {
          top: 20,
          bottom: 20,
          right: 10
        },
        series: [
          {
            name: '催办率(%)',
            data: [1.27, 1.47, 0.97, 1.37, 1.43, 1.39, 1.22, 1.81, 0.82, 0.98, 1.67, 1.83, 1.29],
            type: 'line',
            smooth: true,
            symbol: 'circle',
            itemStyle: {
              color: '#ff0000'
            },
            areaStyle: {
              opacity: 0.2
            }
          }
        ]
      }
    }
  }
}
</script>

<style lang="less" scoped>
.overview-box {
  text-align: center;
}
.overview-box p{
  float: left;
  margin-left: 30px;
  text-align: left;
  color: rgb(73, 245, 241);
}
.chart-box {
  width: 350px;
  margin: 0 auto;
  height: 190px;
}
</style>
