<template>
  <div class="overview-box">
    <th-sub-title text="完整社区数据体检" icon="icon_yuanqujieshao" />
    <span>依据：国家重点专项课题《城镇老旧小区改造监测诊断技术集成及示范应用》《完整居住社区建设指南》</span> <br>
    <span>内容：基于社区人居环境评价的各类口径数据，对社区状态进行持续跟踪</span>
    <th-radio-group :btns="tabs" v-model="activeTab" @on-change="updateChart" style="margin-top: 20px;"/>
    <div class="chart"></div>
  </div>
</template>

<script>
import _ from 'lodash'
export default {
  methods: {
    updateChart() {
      if (this.activeTab === '年龄分布') {
        this.pieOption.series[0].data = [{name: '0-14岁', value: 1357}, {name: '15-64岁', value:8178}, {name: '65岁以上', value:475}]
      } else if (this.activeTab === '通勤方式') {
        this.pieOption.series[0].data = [{name: '步行', value: 7.8}, {name: '地铁（公交）', value:42}, {name: '驾车（打车）', value:30}, {name: '自行车', value:19}, {name: '其他', value:1.2}]
      } else {
        this.pieOption.series[0].data = [{name: '公务员事业单位', value: 41}, {name: '企业', value:30}, {name: '个体户', value:20}, {name: '自由职业', value:3}, {name: '其他', value:6}]
      }
      this.chart.setOption(this.pieOption)
    }
  },
  mounted() {
    this.chart = echarts.init(this.$el.querySelector('.chart'))
    this.updateChart()
  },
  data() {
    return {
      activeTab: '年龄分布',
      tabs: [
        {
          name: '年龄分布',
          value: '年龄分布'
        },
        {
          name: '通勤方式',
          value: '通勤方式'
        },
        {
          name: '职业类型',
          value: '职业类型'
        }
      ],
      pieOption: {
        title: {
          text: '',
          left: 'center',
          textStyle: {
            color: '#F6A416',
            fontSize: 14
          }
        },
        tooltip: {
          trigger: 'item'
        },
        legend: {
          show: false
        },
        series: [
          {
            name: '',
            type: 'pie',
            radius: '50%',
            color: ['#297eff', '#36cd9f', '#ca29ff', '#29ebff', '#e8ff29'],
            avoidLabelOverlap: true,
            label: {
              show: true,
              formatter: '{b}\n{d}%',
              color: '#ffffff',
              lineHeight: 18
            },
            labelLine: {
              show: true
            },
            data: []
          }
        ]
      }
    }
  }
}
</script>

<style lang="less" scoped>
.overview-box {
  text-align: center;
}
.overview-box span{
  margin-left: 30px;
  text-align: left;
  color: rgb(73, 245, 241);
}
.chart{
  width: 350px;
  height: 280px;
  margin: 0px auto;
}
</style>
