<template>
  <div class="env-box">
    <div class="time"></div>
    <div class="item" v-for="item in list" :key="item.name">
      <div class="label">
        <div class="name">
          <th-icon :name="item.icon" width="24" height="24" v-if="item.icon" />
          <span style="margin-left: 5px;">{{item.name}}</span>
        </div>
        <div class="value">
          <th-countup class="v-value" :size="20" :value="item.value" :color="colors[item.status]" />
          <span class="unit">{{ item.unit }}</span>
        </div>
      </div>
      <Progress :percent="getPercent(item.value, item.max)" :stroke-width="10" :stroke-color="colors[item.status]" hide-info />
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    getPercent(value, max) {
      return Number((value * 100 / max).toFixed(2))
    }
  },
  data() {
    return {
      colors: {
        good: '#19be6b',
        normal: '#ff9900',
        warning: '#ed4014'
      },
      list: [
        {
          name: '每日数据量',
          value: 53456,
          max: 60000,
          unit: '条',
          icon: 'icon_wendu',
          status: 'good'
        },
        {
          name: '智慧场景',
          value: 2,
          max: 10,
          unit: '个',
          icon: 'icon_shidu',
          status: 'good'
        },
        {
          name: '接入设备',
          value: 256,
          max: 300,
          unit: '个',
          icon: 'icon_noise',
          status: 'good'
        },
        {
          name: '日服务次数',
          value: 89,
          max: 100,
          unit: '次',
          icon: 'icon_zhaodu',
          status: 'good'
        },
        {
          name: '事件种类',
          value: 33,
          max: 100,
          unit: '种',
          icon: 'icon_pm',
          status: 'good'
        },
        {
          name: '服务住户',
          value: 3437,
          max: 3500,
          unit: '户',
          icon: 'icon_pm',
          status: 'good'
        },
        {
          name: '建筑面积',
          value: 45869,
          max: 50000,
          unit: '㎡',
          icon: 'icon_fengsu',
          status: 'good'
        }
      ]
    }
  }
}
</script>

<style lang="less" scoped>
.env-box{
  width: calc(100% - 60px);
  margin: 0 auto;
  font-size: 16px;
  .item{
    border-bottom: 1px dashed 1px dashed rgb(0 204 255 / 37%);
    padding-bottom: 5px;
    .label{
      position: relative;
      top: 10px;
    }
  }
  .name{
    float: left;
  }
  .value{
    float: right;
  }
  .unit{
    margin-left: 5px;
  }
}
</style>