<template>
  <div class="notice">
    <div class="head">
      <span class="title">{{ title }}</span>
      <span class="time" style="float: right;">{{ time }}</span>
    </div>
    <div class="content">
      <div class="location">
        <th-icon name="icon_weizhi" width="14" height="18" />
        <span style="color: #00FFA9;">位置：</span>
        <span>{{ location }}</span>
      </div>
      <p>{{ content }}</p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: ''
    },
    time: {
      type: String,
      default: ''
    },
    location: {
      type: String,
      default: ''
    },
    content: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="less" scoped>
.notice {
  width: 100%;
  background-color: rgba(0, 147, 252, 0.15);
  margin-bottom: 20px;
  .head {
    width: 100%;
    height: 32px;
    background-color: rgba(29, 83, 149, 0.8);
    line-height: 32px;
    padding: 0 20px;
    .title {
      font-size: 16px;
      color: #f0dc84;
    }
    .time {
      color: #ffffff;
      font-size: 14px;
    }
  }
  .content {
    width: calc(100% - 40px);
    padding: 20px 20px 10px 20px;
    .location {
      font-size: 14px;
      font-weight: bold;
      .icon {
        margin-right: 10px;
      }
    }
    p {
      text-align: justify;
      margin: 5px 0;
      line-height: 25px;
      font-size: 14px;
    }
  }
}
</style>
