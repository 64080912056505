import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Health from '@/views/Health'
import Monitor from '@/views/Monitor'
import Login from '@/views/Login'
import User from '@/views/User'
import Public from '@/views/Public'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: { name: '服务运营' }
  },
  {
    path: '/overview',
    name: '服务运营',
    component: Home
  },
  {
    path: '/health',
    name: '社区体检',
    component: Health
  },
  {
    path: '/monitor',
    name: '社区监测',
    component: Monitor
  },
  {
    path: '/login',
    name: '登录',
    component: Login
  },
  {
    path: '/user',
    name: '用户管理',
    component: User
  },
  {
    path: '/public',
    name: '公众参与',
    component: Public
  }
]

const router = new VueRouter({
  routes
})

export default router
