<template>
  <div>
    <HealthMap v-if="page==='社区体检'"/>
    <SceneMap v-else-if="page==='社区监测' && subPage === '智慧场景'"/>
    <MonitorMap v-else-if="page==='社区监测' && subPage === '运维监测'"/>
    <WarningMap v-else-if="page==='社区监测' && subPage === '预警感知'"/>
    <OverviewMap v-else-if="page==='数据大屏'"/>
    <MainMap v-else />
  </div>
</template>

<script>
import HealthMap from './HealthMap.vue'
import MainMap from './MainMap.vue'
import SceneMap from './SceneMap.vue'
import {mapState} from 'vuex'
import MonitorMap from './MonitorMap.vue'
import WarningMap from './WarningMap.vue'
import OverviewMap from './OverviewMap.vue'
export default {
  components: { HealthMap, MainMap, SceneMap, MonitorMap, WarningMap, OverviewMap },
  computed: {
    page() {
      return this.$route.name
    },
    ...mapState({
      subPage: state => state.subPage
    })
  }
}
</script>