<template>
  <div class="content-zone">
    <div class="row head">
      <div class="col inline">事件名称</div>
      <div class="col inline">发生时间</div>
      <div class="col inline">位置</div>
    </div>
    <div class="scroll-box">
      <div class="scroll-line"></div>
      <div class="row" v-for="(item, index) in info" :key="index">
        <div class="col inline">{{item['事件名称']}}</div>
        <div class="col inline">{{item['事件发生时间']}}</div>
        <div class="col inline">{{item['位置']}}</div>
      </div>
    </div>
  </div>
</template>

<script>
import util from '../../../libs/util'
export default {
  props: ['info'],
  methods: {
    scroll() {
      let scrollLine = this.$el.querySelector('.scroll-line')
      if (this.currentItem >= this.info.length) {
        scrollLine.style.marginTop = 0
        this.currentItem = 9
      } else {
        this.currentItem += 1
        scrollLine.style.marginTop = ((this.currentItem - 9) * -40) + 'px'
      }
    },
    formatTime(time) {
      return util.formatTime('HH:mm:ss', new Date(time))
    }
  },
  mounted() {
    setInterval(() => {
      this.scroll()
    }, 3000)
  },
  beforeDestroy() {
    clearInterval(this.timer)
  },
  data () {
    return {
      currentItem: 9
    }
  }
}
</script>

<style lang="less" scoped>
.content-zone {
  padding: 5px;
  width: 100%;
  margin-bottom: 10px;
  height: 100%;
  width: calc(100% - 40px);
  margin: 0 auto;
  .row{
    width: 100%;
    height: 40px;
    background-color: rgba(0, 232, 253, 0.1);
    color: #ffffff;
    font-size: 14px;
    line-height: 40px;
    &.head{
      background-color: rgba(0, 255, 169, 0.25);
      color: #01E99B;
      font-size: 16px;
    }
    .col{
      width: 33.33%;
      text-align: center;
      &:first-child{
        width: 25%;
      }
      &:nth-child(2) {
        width: 25%;
      }
      &:nth-child(3) {
        width: 45%;
      }
    }
  }
  .scroll-box{
    width: 100%;
    height: 370px;
    overflow: hidden;
    .scroll-line{
      margin-top: 0;
      transition: all .3s;
    }
  }
}
</style>>