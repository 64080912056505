<template>
  <div class="overview-box">
    <th-sub-title text="智慧场景人数变化" icon="icon_gongnengjiance" />
    <div class="chart-box rel">
    </div>
  </div>
</template>

<script>
import util from '../../../libs/util'
export default {
  methods: {
    updateChart() {
      let xData = []
      let now = new Date().getTime()
      for(let i = 0;i < 7;i++) {
        let date = new Date(now - (6-i) * 24 * 60 * 60 * 1000)
        xData.push(util.formatTime('M月d日', date))
      }
      this.option.xAxis.data = xData
      this.myChart.setOption(this.option)
    }
  },
  mounted() {
    this.myChart = echarts.init(this.$el.querySelector('.chart-box'))
    this.updateChart()
  },
  data() {
    return {
      option: {
        tooltip: {
          show: true,
          trigger: 'axis'
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
          axisLabel: {
            color: '#ffffff',
            rotate: -30
          },
          axisLine: {
            show: false
          },
          axisTick: {
            show: false
          }
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            color: '#ffffff'
          },
          splitLine: {
            lineStyle: {
              type: 'dashed',
              color: 'rgba(204, 204, 204, 0.3)'
            }
          }
        },
        grid: {
          top: 20,
          bottom: 40,
          right: 50
        },
        series: [
          {
            name: '每日使用人次',
            data: [134,165,178,167,353,234,1124],
            type: 'line',
            smooth: true,
            symbol: 'circle',
            itemStyle: {
              color: '#36cd9f'
            },
            areaStyle: {
              opacity: 0.2
            }
          }
        ]
      }
    }
  }
}
</script>

<style lang="less" scoped>
.overview-box {
  text-align: center;
}
.chart-box {
  width: 350px;
  margin: 20px auto;
  height: 190px;
}
</style>
