<template>
  <div class="overview-box">
    <th-sub-title text="接入智慧场景设施" icon="icon_shebeijiance" />
    <th-indicator-v class="inline" label="智慧运动场景" :value="1" unit=""/>
    <th-indicator-v class="inline" label="环境感知" :value="1" unit=""/>
    <th-indicator-v class="inline" label="智慧交互设施" :value="1" unit=""/>
  </div>
</template>

<script>
export default {
  methods: {
    
  },
  mounted() {
  },
  data() {
    return {
    }
  }
}
</script>

<style lang="less" scoped>
.overview-box {
  text-align: center;
}
.inline{
  width: 110px;
  margin: 20px 10px;
}
</style>
