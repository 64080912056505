<template>
  <div class="overview-box">
    <th-sub-title text="接入设备列表" icon="icon_shebeijiance" />
    <div class="result-panel">
      <th-text-indicator :label="item.name" :value="item.count" :unit="item.unit" :meta="item.warnings?(item.warnings+'个设备报警'):''" v-for="(item, index) in list" :key="index"/>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    
  },
  mounted() {
  },
  data() {
    return {
      list: [
        {
          name: '全部',
          count: 601,
          unit: '个',
          warnings: 4
        },
        {
          name: '摄像机',
          count: 213,
          unit: '个',
          warnings: 4
        },
        {
          name: '门禁',
          count: 85,
          unit: '个',
          warnings: 0
        },
        {
          name: '井盖',
          count: 99,
          unit: '个',
          warnings: 0
        },
        {
          name: '消防栓',
          count: 1,
          unit: '个',
          warnings: 0
        },
        {
          name: '电弧',
          count: 2,
          unit: '个',
          warnings: 1
        },
        {
          name: '烟感',
          count: 171,
          unit: '个',
          warnings: 0
        },
        {
          name: '电表',
          count: 28,
          unit: '个',
          warnings: 2
        },
        {
          name: '可燃气体',
          count: 1,
          unit: '个',
          warnings: 0
        },
        {
          name: '地磁',
          count: 1,
          unit: '个',
          warnings: 0
        }
      ]
    }
  }
}
</script>

<style lang="less" scoped>
.overview-box {
  text-align: center;
}
.result-panel{
  margin-top: 25px;
}
</style>
