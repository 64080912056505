<template>
  <Form :model="options" :label-width="80">
    <FormItem label="昵称">
      <Input v-model="options.nickname" size="large" placeholder="输入昵称" style="width: 300px;"></Input>
    </FormItem>
    <FormItem label="邮箱">
      <Input v-model="options.email" size="large" placeholder="输入邮箱" style="width: 300px;"></Input>
    </FormItem>
    <FormItem label="电话">
      <Input v-model="options.mobile" size="large" placeholder="输入电话" style="width: 300px;"></Input>
    </FormItem>
  </Form>
</template>

<script>
export default {
  props: ['options']
}
</script>