<template>
  <div class="user">
    <div class="username inline" @click="login">管理员入口</div>
  </div>
</template>

<script>
export default {
  methods: {
    login() {
      this.$router.push({
        name: '登录'
      })
    }
  }
}
</script>

<style lang="less" scoped>
.user {
  color: #a3bfd3;
  font-size: 18px;
  line-height: 56px;
  position: absolute;
  right: 40px;
  top: 0;
  .username{
    cursor: pointer;
  }
}
</style>
