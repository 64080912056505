<template>
  <div class="user">
    <div class="title">
      国家重点专项课题成果转化示范应用——融创奥城智慧社区
    </div>
    <div class="panel">
      <div class="head">用户列表</div>
      <div>
        <Input search placeholder="输入关键字搜索" style="width: 300px;margin-bottom: 10px;"/>
        <Button type="primary" icon="md-add" @click="showCreateDlg" style="float: right;">添加用户</Button>
      </div>
      <Table border :columns="cols" :data="list"></Table>
    </div>
    <Modal
        v-model="newDlg"
        title="添加新用户"
        width="450"
        :mask-closable="false"
        :closable="false"
        @on-ok="createUser"
        @on-cancel="createUserCancel">
        <CreateUserForm ref="newForm" v-if="newDlg"/>
    </Modal>
    <Modal
        v-model="editDlg"
        title="修改信息"
        width="450"
        :mask-closable="false"
        :closable="false"
        @on-ok="updateUser">
        <EditForm ref="editForm" :options="editOption" v-if="editDlg"/>
    </Modal>
    <Modal
        v-model="pwdDlg"
        title="修改密码"
        width="450"
        :mask-closable="false"
        :closable="false"
        @on-ok="updatePassword">
        <PasswordForm ref="pwdForm" v-if="pwdDlg"/>
    </Modal>
  </div>
</template>

<script>
import CreateUserForm from '../components/admin/CreateUserForm.vue'
import EditForm from '../components/admin/EditForm.vue'
import PasswordForm from '../components/admin/PasswordForm.vue'
import axios from 'axios'
import Cookie from 'js-cookie'
export default {
  components: {
    CreateUserForm,
    EditForm,
    PasswordForm
  },
  methods: {
    getList() {
      axios.get(`${API}/users/`).then(res => {
        this.list = res.data
      })
    },
    showCreateDlg() {
      this.newDlg = true
    },
    show(index) {
      this.newDlg = true
    },
    remove(index) {
      let username = this.list[index].username
      this.$Modal.confirm({
        title: '警告',
        content: '是否确认删除？删除后不可恢复！',
        onOk: () => {
          axios.delete(`${API}/users/${username}`).then(res => {
            this.$Notice.success({
              title: '删除成功'
            })
            this.getList()
          })
        },
        onCancel: () => {}
      });
    },
    createUser() {
      let option = this.$refs.newForm.options
      axios.post(`${API}/users/regist`, option).then(res => {
        this.$Notice.success({
          title: '注册成功',
          des: `成功创建用户【${option.username}】`
        })
        this.getList()
      })
    },
    createUserCancel() {
      this.newDlg = false
    },
    showEditDlg(index) {
      this.editDlg = true
      this.editOption = JSON.parse(JSON.stringify(this.list[index]))
    },
    showPwdDlg(index) {
      this.pwdDlg = true
      this.editOption = JSON.parse(JSON.stringify(this.list[index]))
    },
    updateUser() {
      let {username} = this.editOption
      axios.put(`${API}/users/${username}`, this.editOption).then(res => {
        this.$Notice.success({
          title: '信息修改成功'
        })
        this.getList()
      })
    },
    updatePassword() {
      let {oldPassword, newPassword} = this.$refs.pwdForm.options
      let {username} = this.editOption
      axios.put(`${API}/users/${username}`, {password: newPassword}).then(res => {
        this.$Notice.success({
          title: '密码修改成功'
        })
      })
    }
  },
  mounted() {
    let login = Cookie.get('username')
    if (!login) {
      this.$router.push({
        name: '登录'
      })
      return
    }
    this.getList()
  },
  data() {
    return {
      editDlg: false,
      newDlg: false,
      pwdDlg: false,
      editOption: {},
      cols: [
        {
          title: '用户名',
          key: 'username'
        },
        {
          title: '姓名',
          key: 'realname'
        },
        {
          title: '昵称',
          key: 'nickname'
        },
        {
          title: '邮箱',
          key: 'email'
        },
        {
          title: '电话号码',
          key: 'mobile'
        },
        {
          title: '角色',
          key: 'role'
        },
        {
          title: '操作',
          key: 'action',
          width: 150,
          align: 'center',
          render: (h, params) => {
            return h('div', [
              h(
                'Button',
                {
                  props: {
                    type: 'primary',
                    size: 'small',
                    shape: 'circle',
                    icon: 'md-create'
                  },
                  style: {
                    marginRight: '5px'
                  },
                  on: {
                    click: () => {
                      this.showEditDlg(params.index)
                    }
                  }
                }
              ),
              h(
                'Button',
                {
                  props: {
                    type: 'error',
                    size: 'small',
                    shape: 'circle',
                    icon: 'md-trash'
                  },
                  style: {
                    marginRight: '5px'
                  },
                  on: {
                    click: () => {
                      this.remove(params.index)
                    }
                  }
                },
              ),
              h(
                'Button',
                {
                  props: {
                    type: 'success',
                    size: 'small',
                    shape: 'circle',
                    icon: 'md-key'
                  },
                  on: {
                    click: () => {
                      this.showPwdDlg(params.index)
                    }
                  }
                },
              )
            ])
          }
        }
      ],
      list: []
    }
  }
}
</script>

<style lang="less" scoped>
.user {
  position: absolute;
  width: 100%;
  height: 100%;
  background-image: url(~@/assets/images/login_bg.jpeg);
  background-size: 100% 100%;
  .title {
    font-size: 48px;
    text-align: left;
    padding: 20px 40px;
  }
  .panel {
    width: calc(100% - 100px);
    height: calc(100% - 150px);
    background-color: #ffffff;
    border-radius: 10px;
    position: absolute;
    left: 50%;
    top: calc(50% + 30px);
    transform: translate(-50%, -50%);
    box-shadow: 0 0 40px #666666;
    padding: 5px 20px;
    text-align: left;
    .head{
      font-size: 20px;
      color: #333;
      text-align: left;
      line-height: 45px;
      border-bottom: 1px solid #ccc;
      margin-bottom: 10px;
    }
  }
}
</style>
