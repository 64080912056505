<template>
  <div class="overview-box">
    <th-sub-title text="人口变化" icon="icon_rytj" />
    <span>（数据来源：手机信令数据-2021年日均人数）</span>
    <div class="chart-box c1 rel"></div>
    <div class="chart-box c2 rel"></div>
  </div>
</template>

<script>
import _ from 'lodash'
export default {
  methods: {
    getOption(type) {
      let option = _.cloneDeep(this.option)
      if (type === '居住人口') {
        option.series[0].data = [
          7904,8300,8398,8012,7145,5123,4320,3805,4678,5802,6349,7102
        ]
      } else {
        option.series[0].data = [
          1507,1306,1200,1800,3903,5010,5510,5600,5398,4321,1902,1698
        ]
      }
      option.series[0].itemStyle.color = this.colors[type]
      option.series[0].name = type
      option.legend.data = [type]
      return option
    },
    updateChart() {
      let option1 = this.getOption('居住人口')
      let option2 = this.getOption('工作人口')
      this.myChart.setOption(option1)
      this.myChart2.setOption(option2)
    },
  },
  mounted() {
    this.myChart = echarts.init(this.$el.querySelector('.chart-box.c1'))
    this.myChart2 = echarts.init(this.$el.querySelector('.chart-box.c2'))
    this.updateChart()
  },
  data() {
    return {
      colors: {
        居住人口: 'rgba(14, 156, 255, 1)',
        工作人口: 'rgba(55, 182, 139, 1)',
      },
      option: {
        tooltip: {
          show: true,
          trigger: 'axis',
        },
        legend: {
          show: 'true',
          textStyle: {
            color: '#ffffff'
          }
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: [
            0,2,4,6,8,10,12,14,16,18,20,22
          ],
          axisLabel: {
            color: '#ffffff',
          },
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            color: '#ffffff',
          },
          splitLine: {
            lineStyle: {
              type: 'dashed',
              color: 'rgba(204, 204, 204, 0.3)',
            },
          },
        },
        grid: {
          top: 30,
          bottom: 20,
          right: 10,
          left: 55,
        },
        series: [
          {
            name: '',
            data: [820, 932, 901, 934, 1290, 1330, 1320],
            type: 'line',
            smooth: true,
            symbol: 'circle',
            itemStyle: {
              color: '#36cd9f',
            },
            areaStyle: {
              opacity: 0.2,
            },
          },
        ],
      },
    }
  },
}
</script>

<style lang="less" scoped>
.overview-box {
  text-align: center;
}

.overview-box span{
  float: left;
  margin-left: 30px;
  text-align: left;
  color: rgb(73, 245, 241);
}
.chart-box {
  width: 350px;
  margin: 20px auto;
  height: 220px;
}
</style>
