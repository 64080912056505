import Vue from 'vue'
import Vuex from 'vuex'
import _ from 'lodash'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    selectedLayers: [
      {
        name: 'POI',
        layers: ['poi']
      }
    ],
    subPage: '预警感知'
  },
  mutations: {
    patch_layers(state, value) {
      state.selectedLayers = value
    },
    patch_sub_page(state, page) {
      state.subPage = page
    }
  },
  actions: {},
  modules: {}
})
