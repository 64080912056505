<template>
  <Form :model="options" :label-width="80">
    <FormItem label="用户名">
      <Input
        v-model="options.username"
        size="large"
        placeholder="输入用户名"
        style="width: 300px"
      ></Input>
    </FormItem>
    <FormItem label="姓名">
      <Input
        v-model="options.realname"
        size="large"
        placeholder="输入姓名"
        style="width: 300px"
      ></Input>
    </FormItem>
    <FormItem label="昵称">
      <Input
        v-model="options.nickname"
        size="large"
        placeholder="输入昵称"
        style="width: 300px"
      ></Input>
    </FormItem>
    <FormItem label="邮箱">
      <Input
        v-model="options.email"
        size="large"
        placeholder="输入邮箱"
        style="width: 300px"
      ></Input>
    </FormItem>
    <FormItem label="电话">
      <Input
        v-model="options.mobile"
        size="large"
        placeholder="输入电话"
        style="width: 300px"
      ></Input>
    </FormItem>
    <FormItem label="角色">
      <Select v-model="options.role" style="width: 300px">
        <Option value="guest">普通用户</Option>
        <Option value="admin">管理员</Option>
      </Select>
    </FormItem>
    <FormItem label="密码">
      <Input
        v-model="options.password"
        size="large"
        type="password"
        placeholder="输入密码"
        style="width: 300px"
      ></Input>
    </FormItem>
  </Form>
</template>

<script>
export default {
  data() {
    return {
      options: {
        username: '',
        realname: '',
        nickname: '',
        password: '',
        mobile: '',
        email: '',
        role: 'guest'
      },
    }
  },
}
</script>