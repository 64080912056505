<template>
  <div class="envi-box">
    <th-sub-title text="态势总览" icon="icon_hjjc" />
    <InfoPanel />
  </div>
</template>

<script>
import InfoPanel from '@/components/share/Environment.vue'
export default {
  components: {
    InfoPanel
  }
}
</script>

<style lang="less" scoped>
.envi-box {
  text-align: left;
}
</style>
