<template>
  <div class="overview-box">
    <th-sub-title text="预警区域排名" icon="icon_yuanqujieshao" />
    <div class="chart"></div>
  </div>
</template>

<script>
import _ from 'lodash'
export default {
  methods: {
  },
  mounted() {
    this.chart = echarts.init(this.$el.querySelector('.chart'))
    this.chart.setOption(this.barOption)
  },
  data() {
    return {
      barOption: {
        yAxis: {
          type: 'category',
          data: ['80#', '77#', '15#', '12#', '10#'],
          inverse: true,
          axisLabel: {
            color: '#ffffff'
          },
          axisLine: {
            show: false
          },
          axisTick: {
            show: false
          }
        },
        tooltip: {
          show: true,
          trigger: 'axis'
        },
        grid: {
          top: 40,
          right: 20,
          bottom: 40
        },
        xAxis: {
          type: 'value',
          position: 'top',
          axisLabel: {
            color: '#ffffff'
          },
          splitLine: {
            lineStyle: {
              type: 'dashed',
              color: 'rgba(204, 204, 204, 0.3)'
            }
          }
        },
        series: [
          {
            name: '预警区域排名',
            data: [130, 120, 99, 88, 60],
            type: 'bar',
            barWidth: 15,
            itemStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 1, 0, [
                { offset: 0, color: '#36cd9f' },
                { offset: 1, color: '#29ebff' }
              ])
            }
          }
        ]
      }
    }
  }
}
</script>

<style lang="less" scoped>
.overview-box {
  text-align: center;
}
.chart{
  width: 350px;
  height: 220px;
  margin: 5px auto;
}
</style>
