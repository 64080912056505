<template>
  <div class="overview-box">
    <th-sub-title text="智慧场景使用情况" icon="icon_gongnengjiance" />
    <div class="chart-box rel">
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    updateChart() {
      let xData = []
      let yData = []
      for (let i = 0; i < 24; i++) {
        xData.push(i + 1)
        let v = Number((Math.random() * 100 + 400).toFixed(2))
        yData.push(v)
      }
      this.option.xAxis.data = xData
      //this.option.series[0].data = yData
      this.myChart.setOption(this.option)
    }
  },
  mounted() {
    this.myChart = echarts.init(this.$el.querySelector('.chart-box'))
    this.updateChart()
  },
  data() {
    return {
      option: {
        tooltip: {
          show: true,
          trigger: 'axis'
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
          axisLabel: {
            color: '#ffffff'
          },
          axisLine: {
            show: false
          },
          axisTick: {
            show: false
          }
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            color: '#ffffff'
          },
          splitLine: {
            lineStyle: {
              type: 'dashed',
              color: 'rgba(204, 204, 204, 0.3)'
            }
          }
        },
        grid: {
          top: 20,
          bottom: 20,
          right: 10
        },
        series: [
          {
            name: '使用人次',
            data: [0,0,0,0,0,0,0,8,12,13,14,15,17,19,20,5,4,1,0,0, 0, 0, 0, 0],
            type: 'line',
            smooth: true,
            symbol: 'circle',
            itemStyle: {
              color: '#36cd9f'
            },
            areaStyle: {
              opacity: 0.2
            }
          }
        ]
      }
    }
  }
}
</script>

<style lang="less" scoped>
.overview-box {
  text-align: center;
}
.chart-box {
  width: 350px;
  margin: 20px auto;
  height: 210px;
}
</style>
