<template>
  <div class="overview-box">
    <th-sub-title text="评价满意度" icon="icon_gjxx" />
    <div class="chart-box rel">
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    updateChart() {
      this.myChart.setOption(this.option)
    }
  },
  mounted() {
    this.myChart = echarts.init(this.$el.querySelector('.chart-box'))
    this.updateChart()
  },
  data() {
    return {
      option: {
        tooltip: {
          show: true,
          trigger: 'axis'
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: ["2021/10/1","2021/10/15","2021/10/29","2021/11/12","2021/11/26","2021/12/10","2021/12/24","2022/1/7","2022/1/21","2022/2/4","2022/2/18","2022/3/4","2022/3/18"],
          axisLabel: {
            color: '#ffffff'
          },
          axisLine: {
            show: false
          },
          axisTick: {
            show: false
          }
        },
        yAxis: {
          type: 'value',
          max: 100,
          axisLabel: {
            color: '#ffffff'
          },
          splitLine: {
            lineStyle: {
              type: 'dashed',
              color: 'rgba(204, 204, 204, 0.3)'
            }
          }
        },
        grid: {
          top: 20,
          bottom: 20,
          right: 10
        },
        series: [
          {
            name: '评价满意度(%)',
            data: [98, 91, 93, 100, 94, 98, 98, 97, 91, 95, 93, 99, 94],
            type: 'line',
            smooth: true,
            symbol: 'circle',
            itemStyle: {
              color: '#36cd9f'
            },
            areaStyle: {
              opacity: 0.2
            }
          }
        ]
      }
    }
  }
}
</script>

<style lang="less" scoped>
.overview-box {
  text-align: center;
}
.chart-box {
  width: 350px;
  margin: 0 auto;
  height: 190px;
}
</style>
