<template>
  <div class="layout-container">
    <div class="screenshot">
      <div class="layout">
        <div class="map abs full">
          <Map v-if="page!='公众参与'"/>
        </div>
        <div class="mask abs full"></div>
        <div class="head abs">
          <div class="title">
            <div class="t1">智慧社区运营中心</div>
            <div class="t2">国家重点专项课题成果转化示范应用</div>
          </div>
          <div class="menus abs">
            <div
              class="menu inline"
              :class="{ active: activeMenu === menu }"
              v-for="menu in menus"
              :key="menu"
              @click="clickMenu(menu)"
            >
              {{ menu }}
            </div>
          </div>
          <div class="time abs">{{ time }}</div>
          <UserInfo class="abs" />
          <Weather />
        </div>
        <div class="content abs">
          <slot></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import util from '@/libs/util'
import Map from '@/components/map/Map'
import UserInfo from './UserInfo'
import Weather from './Weather'
export default {
  computed: {
    page() {
      return this.$route.name
      },
  },
  props: ['mode'], // mode的取值："width"、"height"、"fullscreen"、"original"，分别代表按宽度缩放、按高度缩放、全屏铺满和原始大小
  components: {
    Map,
    UserInfo,
    Weather
  },
  methods: {
    resize() {
      let container = document.querySelector('#app')
      let scale = this.getScale(container, this.mode, this.width, this.height)
      let canvasWidth = container.offsetWidth
      let canvasHeight = container.offsetHeight
      let visualWidth = this.width * scale.scaleX
      let visualHeight = this.height * scale.scaleY
      let paddingX = 0
      let paddingY = 0
      paddingX =
        canvasHeight - visualHeight > 0 ? (canvasHeight - visualHeight) / 2 : 0
      paddingY =
        canvasWidth - visualWidth > 0 ? (canvasWidth - visualWidth) / 2 : 0
      this.css(this.$el.querySelector('.layout'), {
        width: this.width + 'px',
        height: this.height + 'px',
        transform: 'scale(' + scale.scaleX + ',' + scale.scaleY + ')',
        margin: paddingX + 'px ' + paddingY + 'px'
      })
      let overflowX =
        Math.round(this.width * scale.scaleX) > canvasWidth ? 'auto' : 'hidden'
      let overflowY =
        Math.round(this.height * scale.scaleY) > canvasHeight
          ? 'auto'
          : 'hidden'
      this.css(this.$el, {
        width: canvasWidth + 'px',
        height: canvasHeight + 'px',
        'overflow-x': overflowX,
        'overflow-y': overflowY
      })
      this.css(this.$el.querySelector('.screenshot'), {
        width: this.width * scale.scaleX + 'px',
        height: this.height * scale.scaleY + 'px'
      })
    },
    getScale(el, mode, width, height) {
      let scaleX, scaleY
      if (mode === 'width') {
        let scale = el.offsetWidth / width
        scaleX = scaleY = scale > 1 ? 1 : scale
      } else if (mode === 'height') {
        let scale = el.offsetHeight / height
        scaleX = scaleY = scale > 1 ? 1 : scale
      } else if (mode === 'original') {
        scaleX = scaleY = 1
      } else {
        scaleX = el.offsetWidth / width
        scaleY = el.offsetHeight / height
      }
      return { scaleX, scaleY }
    },
    css(el, css) {
      for (let key in css) {
        let k = key.replace(/([A-Z])/g, '-$1').toLowerCase()
        el.style[k] = css[key]
      }
    },
    getTime() {
      this.time = util.formatTime('yyyy-MM-dd　HH:mm:ss')
    },
    clickMenu(menu) {
      if (menu == '在线会商'){
        window.location.href="http://smart-community-regeneration.com"
      } else{
        this.activeMenu = menu
        this.$router.push({
          name: menu
        })
      }
    }
  },
  mounted() {
    this.resize()
    window.onresize = () => {
      this.resize()
    }
    this.getTime()
    this.timer = setInterval(() => {
      this.getTime()
    }, 1000)
    this.activeMenu = this.$route.name || '数据大屏'
  },
  beforeDestroy() {
    clearInterval(this.timer)
  },
  data() {
    return {
      width: 1920,
      height: 1080,
      time: '',
      timer: null,
      menus: ['社区体检', '社区监测', '服务运营','公众参与'],
      activeMenu: '数据大屏'
    }
  }
}
</script>

<style lang="less" scoped>
.layout-container {
  background-color: #000;
  .layout {
    position: relative;
    overflow: hidden;
    transform-origin: 0 0;
    background-size: 100% 100%;
    .mask {
      background-image: url(~@/assets/images/mask.png);
      pointer-events: none;
    }
    .head {
      position: absolute;
      width: 100%;
      height: 80px;
      background-image: url(~@/assets/images/navbar.png);
      background-repeat: no-repeat;
      background-position: bottom;
      .title{
        height: 80px;
        width: 500px;
        margin: 0 auto;
        .t1{
          font-size: 36px;
          letter-spacing: 0.1em;
        }
        .t2{
          color: #bbb;
          font-size: 18px;
          line-height: 20px;
        }
      }
    }
    .menus {
      width: fit-content;
      height: 56px;
      left: 10px;
      top: 0;
      .menu {
        width: 100px;
        height: 100%;
        color: #a3bfd3;
        font-size: 20px;
        line-height: 56px;
        margin: 0 20px;
        cursor: pointer;
        &.active {
          color: #ffffff;
          background-image: url(~@/assets/images/nav_xuanzhong.png);
        }
        &:hover {
          color: #ffffff;
        }
      }
    }
    .time {
      font-size: 18px;
      color: #a3bfd3;
      line-height: 56px;
      top: 0;
      right: 340px;
    }
    .content {
      position: absolute;
      width: 100%;
      height: calc(100% - 80px);
      top: 80px;
      left: 0;
      pointer-events: none;
    }
  }
}
</style>
