<template>
  <div class="home full">
    <div class="tabs">
      <div class="tab inline rel" :class="{active:subPage===tab}" v-for="(tab, index) in tabs" :key="tab" @click="pageChange(tab)">
        <span>{{tab}}</span>
        <div class="des abs" v-if="tips[index]">{{tips[index]}}</div>
      </div>
    </div>
    
    <Warning v-if="subPage==='预警感知'"/>
    <Working v-if="subPage==='智慧场景'"/>
    <Manager v-if="subPage==='运维监测'"/>
  </div>
</template>

<script>
import Warning from './Warning.vue'
import Working from './Working.vue'
import Manager from './Manager.vue'
import {mapState} from 'vuex'
export default {
  components: {
    Warning,
    Working,
    Manager
  },
  methods: {
    pageChange(tab) {
      this.$store.commit('patch_sub_page', tab)
    }
  },
  data() {
    return {
      tabs: ['预警感知', '智慧场景', '运维监测'],
      tips: ['接入社区卡口探头视频图像数据，实时智能识别各类事件，统计与展示事件的时空态势', '接入智慧场景设备数据，统计展示智慧场景运行与使用情况，实时监测社区环境质量', '接入社区设备物联网信息，监测各类设备运行情况，展示设施空间分布与实时报警信息']
    }
  },
  computed: {
    ...mapState({
      subPage: state => state.subPage
    })
  }
}
</script>

<style lang="less" scoped>
.home {
  pointer-events: none;
}
.tabs{
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
  pointer-events: initial;
  top: 20px;
  .tab{
    background-color: rgba(32, 47, 73, 0.4);
    line-height: 40px;
    padding: 0 30px;
    border: 2px solid #168fe5;
    border-radius: 20px;
    font-size: 20px;
    margin: 0 20px;
    cursor: pointer;
    box-shadow: 0 0 10px rgba(22, 219, 229, 0.8) inset;
    &.active{
      background-color: rgba(15, 127, 133, 0.5);
      color: #50dcff;
    }
    &:hover .des{
      display: block;
    }
  }
}
.des{
  background-color: rgba(32, 47, 73, 0.6);
  width: 350px;
  padding: 10px 20px;
  text-align: left;
  left: 50%;
  top: 50px;
  display: none;
  font-size: 18px;
  color: #4BF5F1;
  border-radius: 15px;
  transform: translate(-50%, 0);
}
</style>
