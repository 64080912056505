<template>
  <Form :model="options" :label-width="80">
    <FormItem label="旧密码">
      <Input v-model="options.oldPassword" type="password" placeholder="输入旧密码" style="width: 300px;"></Input>
    </FormItem>
    <FormItem label="新密码">
      <Input v-model="options.newPassword" placeholder="输入新密码" style="width: 300px;"></Input>
    </FormItem>
  </Form>
</template>

<script>
export default {
  data() {
    return {
      options: {
        oldPassword: '',
        newPassword: ''
      }
    }
  }
}
</script>