<template>
  <div class="home full">
    <div class="left-panel">
      <ChangJingSheShi />
      <ShiYongQingKuang />
      <RenShuBianHua />
      <XinXiBiao />
    </div>
    <div class="right-panel">
      <YunXingZhuangTai />
      <HuanJingJianCe />
    </div>
  </div>
</template>

<script>
import ChangJingSheShi from '../components/monitor/working/ChangJingSheShi.vue'
import ShiYongQingKuang from '../components/monitor/working/ShiYongQingKuang.vue'
import RenShuBianHua from '../components/monitor/working/RenShuBianHua.vue'
import XinXiBiao from '../components/monitor/working/XinXiBiao.vue'
import YunXingZhuangTai from '../components/monitor/working/YunXingZhuangTai.vue'
import HuanJingJianCe from '../components/monitor/working/HuanJingJianCe.vue'
export default {
  components: {
    ChangJingSheShi,
    ShiYongQingKuang,
    RenShuBianHua,
    XinXiBiao,
    YunXingZhuangTai,
    HuanJingJianCe
  }
}
</script>

<style lang="less" scoped>
.home {
  pointer-events: none;
}
</style>
