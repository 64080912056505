<template>
  <div class="home full">
    <div class="left-panel">
      <SheQuGaiKuang />
      <YuJingBianHua />
      <YuJingLeiXing />
      <QuYuPaiMing />
    </div>
    <div class="right-panel">
      <EventsList />
    </div>
  </div>
</template>

<script>
import SheQuGaiKuang from '../components/monitor/warning/SheQuGaiKuang.vue'
import YuJingBianHua from '../components/monitor/warning/YuJingBianHua.vue'
import YuJingLeiXing from '../components/monitor/warning/YuJingLeiXing.vue'
import QuYuPaiMing from '../components/monitor/warning/QuYuPaiMing.vue'
import EventsList from '../components/monitor/warning/EventsList.vue'
export default {
  components: {
    SheQuGaiKuang,
    YuJingBianHua,
    YuJingLeiXing,
    QuYuPaiMing,
    EventsList
  }
}
</script>

<style lang="less" scoped>
.home {
  pointer-events: none;
}
</style>
