<template>
  <div class="overview-box">
    <th-sub-title text="实时报警" icon="icon_gjxx" />
    <div class="result-panel">
      <div class="head">
        <table>
          <tr>
            <th>事件类型</th>
            <th>发生位置</th>
            <th>报警设备</th>
            <th>发生时间</th>
          </tr>
        </table>
      </div>
      <div class="content">
        <div class="no-data">
          <div class="img"></div>
          <div class="label">暂无数据~</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    
  },
  mounted() {
  },
  data() {
    return {
    }
  }
}
</script>

<style lang="less" scoped>
.overview-box {
  text-align: center;
}
.result-panel{
  margin-top: 25px;
  .head, .content{
    width: 350px;
    margin: 0 auto;
  }
  .head{
    height: 32px;
    color: #01E99B;
    line-height: 32px;
    background: rgba(0, 255, 169, 0.25);
  }
  .content{
    background: rgba(0, 232, 253, 0.1);
    font-size: 16px;
    line-height: 28px;
    height: 160px;
    overflow: auto;
    .no-data{
      padding: 10px;
    }
    .img{
      width: 143px;
      height: 80px;
      margin: 0 auto;
      background-image: url(~@/assets/images/pic_kongzhuangtai.png);
    }
    .label{
      color: #9BB8DA;
    }
    .tips{
      color: #FF6565;
    }
  }
  table{
    width: calc(100% - 10px);
  }
  td, th{
    width: 70px;
  }
}
</style>
