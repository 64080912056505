<template>
  <div class="overview-box">
    <th-sub-title text="社区情况" icon="icon_yuanqujieshao" />
    <th-indicator-v class="inline" label="占地面积" :value="55" unit="万平方米"/>
    <th-indicator-v class="inline" label="户数" :value="3437" unit=""/>
    <th-indicator-v class="inline" label="人口" :value="10054" unit=""/>
    <th-indicator-v class="inline" label="工作人员" :value="168" unit=""/>
    <th-indicator-v class="inline" label="车位" :value="2777" unit=""/>
    <th-indicator-v class="inline" label="门岗" :value="20" unit=""/>
  </div>
</template>

<script>
export default {
  methods: {
    
  },
  mounted() {
  },
  data() {
    return {
    }
  }
}
</script>

<style lang="less" scoped>
.overview-box {
  text-align: center;
}
.inline{
  width: 90px;
  margin: 10px 20px;
}
</style>
