<template>
  <div class="map">
    <div class="map-container"></div>
  </div>
</template>

<script>
import mapboxgl from 'mapbox-gl'
mapboxgl.accessToken =
  'pk.eyJ1Ijoid2FueWFueWFuIiwiYSI6Im1uNVZnTncifQ.90XY40_yjpItUHO8HnbbpA'

export default {
  methods: {
    addMarkers(data) {
      data.forEach(item => {
        let {name, intro, func, pic, x, y} = item
        let el = document.createElement('div')
        el.className = 'scene-marker'
        let imgName = name === '智慧社区-环境感知'?'icon_jdx' : 'icon_bldx'
        el.style.backgroundImage = `url(./icons/${imgName}.png)`
        let marker = new mapboxgl.Marker({
          element: el
        })
        .setLngLat([x, y])
        .setPopup(new mapboxgl.Popup({maxWidth: '400px'}).setHTML(this.renderPopup({name, intro, func, pic})))
        .addTo(this.map)
      })
    },
    renderPopup(property) {
      return `
        <div class="scene-popup">
          <div class="title">${property.name}</div>
          <div class="border-line"></div>
          <div class="label">简介：</div>
          <div class="text">${property.intro}</div>
          <div class="label">功能：</div>
          <div class="text">${property.func}</div>
          <div class="map-img"><img src="${property.pic}" alt=""></div>
        </div>
      `
    }
  },
  mounted() {
    this.map = window.map = new mapboxgl.Map({
      container: this.$el.querySelector('.map-container'),
      style: './maps/map.json',
      bearing: 0,
      pitch: 0,
      center: [117.16, 39.0758],
      zoom: 15.4
    })
    fetch('./data/scenes.json').then(res => res.json()).then(data => {
      this.addMarkers(data)
    })
  },
  data() {
    return {
      map: {}
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
.map, .map-container {
  position: absolute;
  width: 1920px;
  height: 1080px;
  text-align: left;
}
</style>
