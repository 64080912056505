<template>
  <div class="button-group" @mouseenter="stopPlay" @mouseleave="startPlay">
    <div class="btn" :class="{active:activeTab===item.value}" v-for="item in btns" :key="item.value" @click="handleClick(item)" :style="`width: calc(${100/btns.length}%);`">{{item.name}}</div>
  </div>
</template>

<script>
export default {
  model: {
    prop: 'activeTab',
    event: 'on-change'
  },
  props: ['btns', 'activeTab', 'autoplay'],
  methods: {
    handleClick(item) {
      this.$emit('on-change', item.value)
    },
    startPlay() {
      if (!this.autoplay) {
        return
      }
      let n = this.btns.length
      let btns = this.$el.querySelectorAll('.btn')
      this.timer = setInterval(() => {
        this.current = (this.current + 1) % n
        btns[this.current].click()
      }, 6000)
    },
    stopPlay() {
      clearInterval(this.timer)
    }
  },
  mounted() {
    this.startPlay()
  },
  data() {
    return {
      current: 0
    }
  },
  beforeDestroy() {
    this.stopPlay()
  }
}
</script>

<style lang="less" scoped>
.button-group{
  text-align: left;
  width: 360px;
  margin: 0 auto;
  font-size: 16px;
  border: 1px solid #4BF5F1;
  border-radius: 3px;
  .btn{
    background-color: #006a7e;
    outline: 1px solid #75f1ef55;
    line-height: 35px;
    cursor: pointer;
    text-align: center;
    display: inline-block;
    vertical-align: middle;
    &:not(:last-child){
      border-right: none;
    }
    &.active{
      background-color: #4BF5F1;
      color: #002136;
    }
  }
}
</style>