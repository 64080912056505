<template>
  <div class="map">
    <div class="map-container"></div>
  </div>
</template>

<script>
import mapboxgl from 'mapbox-gl'
mapboxgl.accessToken =
  'pk.eyJ1Ijoid2FueWFueWFuIiwiYSI6Im1uNVZnTncifQ.90XY40_yjpItUHO8HnbbpA'

export default {
  methods: {
    addMarkers(data) {
      data.forEach(item => {
        let {name, type, intro, status, x, y} = item
        let el = document.createElement('div')
        el.className = 'scene-marker'
        el.style.backgroundImage = `url(./icons/${type}.png)`
        let marker = new mapboxgl.Marker({
          element: el
        })
        .setLngLat([x, y])
        .setPopup(new mapboxgl.Popup({maxWidth: '300px'}).setHTML(this.renderPopup({name, type, intro, status})))
        .addTo(this.map)
      })
    },
    renderPopup(property) {
      return `
        <div class="monitor-popup">
          <div class="title">${property.name}</div>
          <div class="border-line"></div>
          <div class="label">简介：</div>
          <div class="text">${property.intro}</div>
          <div class="label">状态：</div>
          <div class="text ${property.status}">${property.status}</div>
        </div>
      `
    }
  },
  mounted() {
    this.map = window.map = new mapboxgl.Map({
      container: this.$el.querySelector('.map-container'),
      style: './maps/map.json',
      bearing: 0,
      pitch: 0,
      center: [117.16, 39.0758],
      zoom: 15.4
    })
    fetch('./data/监测设备.json').then(res => res.json()).then(data => {
      this.addMarkers(data)
    })
  },
  data() {
    return {
      map: {}
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
.map, .map-container {
  position: absolute;
  width: 1920px;
  height: 1080px;
  text-align: left;
}
</style>
