<template>
  <div class="overview-box">
    <div class="table inline">
      <div class="lt">
        <th-icon name="icon_ren" width="22" height="22"/>
        <span>社区交通-卡口流量</span>
      </div>
      <div class="head">
        <table>
          <tr>
            <th>出入口</th>
            <th>交通流量（次/日）</th>
          </tr>
        </table>
      </div>
      <div class="content">
        <table>
          <tr v-for="(item, index) in list1" :key="index">
            <td><div>{{item.key}}</div></td>
            <td><div>{{item.value}}</div></td>
          </tr>
        </table>
      </div>
    </div>
    <div class="table inline">
      <div class="lt">
        <th-icon name="icon_che" width="22" height="22"/>
        <span>社区交通-停车场</span>
      </div>
      <div class="head">
      <table>
        <tr>
          <th>停车场</th>
          <th>交通流量（次/日）</th>
        </tr>
      </table>
    </div>
    <div class="content">
      <table>
        <tr v-for="(item, index) in list2" :key="index">
          <td><div>{{item.key}}</div></td>
          <td><div>{{item.value}}</div></td>
        </tr>
      </table>
    </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
export default {
  methods: {
  },
  mounted() {
    
  },
  data() {
    return {
      list1: [
        {
          key: '西门',
          value: 402
        },
        {
          key: '北门',
          value: 590
        },
        {
          key: '东门',
          value: 123
        }
      ],
      list2: [
        {
          key: '1号停车场',
          value: 235
        },
        {
          key: '2号停车场',
          value: 90
        },
        {
          key: '3号停车场',
          value: 123
        },
        {
          key: '4号停车场',
          value: 188
        },
        {
          key: '5号停车场',
          value: 124
        },
        {
          key: '地面停车场',
          value: 48
        }
      ]
    }
  }
}
</script>

<style lang="less" scoped>
.overview-box {
  text-align: center;
}
.table{
  width: 400px;
  margin: 0 20px;
  .lt {
    width: 370px;
    height: 32px;
    margin: 10px auto;
    font-size: 16px;
    color: #4BF5F1;
    line-height: 32px;
    background: linear-gradient(
      to right,
      rgba(55, 182, 139, 0),
      rgba(0, 232, 253, 0.15),
      rgba(55, 182, 139, 0)
    );
    .icon{
      position: relative;
      top: -2px;
    }
    span {
      margin: 0 10px;
    }
  }
}
.head{
  height: 32px;
  color: #01E99B;
  line-height: 32px;
  font-size: 16px;
  background: rgba(0, 255, 169, 0.25);
}
.content{
  background: rgba(0, 232, 253, 0.1);
  line-height: 28px;
  max-height: 104px;
  overflow: auto;
}
table{
  width: calc(100% - 10px);
}
tr{
  height: 32px;
}
</style>
