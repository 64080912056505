<template>
  <div class="indi-h">
    <th-icon :name="icon" width="24" height="24" v-if="icon" />
    <span>{{ label }}：</span>
    <th-countup :value="value" color="#4bf5f1" :size="24" />
    <span class="unit">{{ unit }}</span>
  </div>
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    value: {
      type: [Number, String],
      default: 0
    },
    unit: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="less" scoped>
.indi-h {
  font-size: 16px;
  color: #ffffff;
  .icon {
    margin-right: 5px;
  }
}
</style>
