<template>
  <div class="home full">
    <div class="left-panel">
      <JuMingHuaXiang />
      <RenKou />
    </div>
    <div class="right-panel">
      <Facilities />
    </div>
    <div class="bottom-panel">
      <Traffic />
    </div>
  </div>
</template>

<script>
import JuMingHuaXiang from '@/components/health/JuMingHuaXiang'
import RenKou from '@/components/health/RenKou'
import Facilities from '@/components/health/Facilities'
import Traffic from '@/components/health/Traffic'
export default {
  components: {
    JuMingHuaXiang,
    RenKou,
    Facilities,
    Traffic
  },
  name: 'Home'
}
</script>

<style lang="less" scoped>
.home {
  pointer-events: none;
}
.bottom-panel{
  width: 880px;
  position: absolute;
  left: 50%;
  bottom: 20px;
  padding-bottom: 10px;
  transform: translate(-50%, 0);
  background-color: rgba(32, 47, 73, 0.4);
  pointer-events: initial;
  border-radius: 5px;
}
</style>
