<template>
  <div class="map">
    <div class="map-container"></div>
  </div>
</template>

<script>
import mapboxgl from 'mapbox-gl'
mapboxgl.accessToken =
  'pk.eyJ1Ijoid2FueWFueWFuIiwiYSI6Im1uNVZnTncifQ.90XY40_yjpItUHO8HnbbpA'

export default {
  methods: {
    mapMouseMove(e) {
      if (!this.selectedLayers.find(item => item.name === 'POI')) {
        this.removePopup()
        return
      }
      var selectThreshold = 2;
      var queryBox = [
        [e.point.x - selectThreshold, e.point.y + selectThreshold], // bottom left (SW)
        [e.point.x + selectThreshold, e.point.y - selectThreshold] // top right (NE)
      ];
      var features = this.map.queryRenderedFeatures(queryBox, {layers: ['poi']}) || [];
      if (!features.length) {
        this.removePopup()
        this.map.getCanvas().style.cursor = "grab";
      } else {
        this.map.getCanvas().style.cursor = "pointer";
        let {Name} = features[0].properties
        if (!this.popup) {
          this.popup = new mapboxgl.Popup({
            closeButton: false
          }).addTo(this.map)
        }
        this.popup.setText(Name).setLngLat(e.lngLat)
      }
    },
    removePopup() {
      this.popup && this.popup.remove()
      this.popup = null
    }
  },
  mounted() {
    this.map = window.map = new mapboxgl.Map({
      container: this.$el.querySelector('.map-container'),
      style: './maps/map.json',
      bearing: 0,
      pitch: 45,
      center: [117.16, 39.0758],
      zoom: 15.4
    })
  },
  data() {
    return {
      map: {}
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
.map, .map-container {
  position: absolute;
  width: 1920px;
  height: 1080px;
  text-align: left;
}
</style>
