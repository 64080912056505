<template>
  <img class="icon" :style="`width:${width}px;height:${height}px;`" :src="iconUrl" :alt="name" @click="$emit('on-click')" draggable="false">
</template>

<script>
export default {
  props: ['name','width','height'],
  computed: {
    iconUrl: function() {
      return require('@/assets/icons/' + this.name + '.png')
    }
  }
}
</script>

<style scoped>
.icon {
  display: inline-block;
  vertical-align: middle;
  /* position: relative;
  top: 50%;
  transform: translateY(-50%); */
}
</style>
