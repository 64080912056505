<template>
  <div class="overview-box">
    <th-sub-title text="设施运行情况信息" icon="icon_gongnengjiance" />
    <div class="result-panel">
      <div class="head">
        <table>
          <tr>
            <th>时间</th>
            <th>设施</th>
            <th>预警</th>
            <th>处理情况</th>
          </tr>
        </table>
      </div>
      <div class="content">
        <table>
          <tr v-for="(item, index) in sheshiData" :key="index">
            <td>{{item['时间']}}</td>
            <td>{{item['设施']}}</td>
            <td>{{item['预警']}}</td>
            <td>{{item['处理情况']}}</td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import sheshiData from '@/constants/sheshi_yj'
export default {
  methods: {
    
  },
  mounted() {
  },
  data() {
    let ref = moment()
    return {
      sheshiData: sheshiData.map(d => {
        let diff = Math.round(Math.random() * 3600 + 240 * 60)
        ref = ref.subtract(diff, 'seconds')
        d['时间'] = ref.format('YYYY/MM/DD HH:mm:ss')
        return d
      })
    }
  }
}
</script>

<style lang="less" scoped>
.overview-box {
  text-align: center;
}
.result-panel{
  margin-top: 25px;
  .head, .content{
    width: 350px;
    margin: 0 auto;
  }
  .head{
    height: 32px;
    color: #01E99B;
    line-height: 32px;
    background: rgba(0, 255, 169, 0.25);
  }
  .content{
    background: rgba(0, 232, 253, 0.1);
    font-size: 16px;
    line-height: 28px;
    height: 160px;
    overflow: auto;
    .no-data{
      padding: 10px;
    }
    .img{
      width: 143px;
      height: 80px;
      margin: 0 auto;
      background-image: url(~@/assets/images/pic_kongzhuangtai.png);
    }
    .label{
      color: #9BB8DA;
    }
    .tips{
      color: #FF6565;
    }
  }
  table{
    width: calc(100% - 10px);
  }
  td, th{
    width: 70px;
  }
}
</style>
