<template>
  <div class="overview-box">
    <th-sub-title text="完整社区设施体检" icon="icon_shebeijiance" />
    <span>（数据来源：互联网与调研数据）</span>
    <table>
      <tr>
        <th>目标</th>
        <th>设施</th>
        <th>现状</th>
        <th>达标</th>
      </tr>
      <tr>
        <td rowspan="5" class="t1">基本公共服务设施完善</td>
        <td>社区综合服务站</td>
        <td>1个</td>
        <td>1个</td>
      </tr>
      <tr>
        <td>幼儿园</td>
        <td>1个</td>
        <td>1个</td>
      </tr>
      <tr>
        <td>托儿所</td>
        <td>1个</td>
        <td>1个</td>
      </tr>
      <tr>
        <td>老年服务站</td>
        <td>1个</td>
        <td>1个</td>
      </tr>
      <tr>
        <td>社区服务站</td>
        <td>1个</td>
        <td>1个（不小于120平米）</td>
      </tr>
      <tr>
        <td rowspan="2" class="t1">便民商业服务设施健全</td>
        <td>一个综合超市</td>
        <td>1个</td>
        <td>1个</td>
      </tr>
      <tr>
        <td>多个邮件和快件寄递服务设施</td>
        <td>3个</td>
        <td>1个</td>
      </tr>
      <tr>
        <td rowspan="4" class="t1">市政配套基础设施完备</td>
        <td>停车及充电设施</td>
        <td>1:1.5</td>
        <td>不低于1车位.户</td>
      </tr>
      <tr>
        <td>慢行系统</td>
        <td>5分钟到公交站</td>
        <td>5分钟生活圈设施完备</td>
      </tr>
      <tr>
        <td>无障碍设施</td>
        <td>有</td>
        <td>有</td>
      </tr>
      <tr>
        <td>环境卫生设施</td>
        <td>1个</td>
        <td>1个</td>
      </tr>
      <tr>
        <td rowspan="2" class="t1">公共活动空间充足</td>
        <td>公共活动场地</td>
        <td>1200平米</td>
        <td>700平米</td>
      </tr>
      <tr>
        <td>公共绿地</td>
        <td>5000平米</td>
        <td>2000平米</td>
      </tr>
    </table>
    <a class="link" href="http://smart-community-regeneration.com/" target="_blank">智慧化改造技术导则</a>
  </div>
</template>

<script>
import _ from 'lodash'
export default {
  methods: {
  },
  mounted() {
    
  },
  data() {
    return {
      
    }
  }
}
</script>

<style lang="less" scoped>
.overview-box {
  text-align: center;
}
.overview-box span{
  float: left;
  margin-left: 30px;
  text-align: left;
  color: rgb(73, 245, 241);
}
  table{
    width: calc(100% - 10px);
    font-size: 14px;
    tr{
      cursor: pointer;
    }
    th{
      height: 32px;
      color: #01E99B;
      line-height: 32px;
      font-size: 16px;
      background: rgba(0, 255, 169, 0.25);
    }
    td{
      background: rgba(0, 232, 253, 0.1);
      line-height: 26px;
      padding: 10px;
    }
    td.t1{
      font-size: 16px;
      font-weight: bold;
    }
  }
.link{
  font-size: 20px;
  color: #4bf5f1;
  line-height: 50px;
}
</style>
