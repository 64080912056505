<template>
  <div class="st">
    <th-icon :name="icon" :width="40" :height="40" />
    <div class="text inline">{{ text }}</div>
    <div class="tip" :class="size">
      <div class="tip-line inline"></div>
      <div class="tip-point inline"></div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['text', 'icon', 'size']
}
</script>

<style lang="less" scoped>
.st {
  width: calc(100% - 50px);
  margin: 5px auto;
  height: 40px;
  font-size: 20px;
  text-align: left;
  position: relative;
  .icon {
    width: 30px;
    height: 30px;
  }
  .text {
    width: fit-content;
    line-height: 40px;
    position: relative;
    color: #4bf5f1;
    margin-left: 10px;
  }
  .tip {
    float: right;
    margin: 3px 0;
    &.small .tip-line {
      width: 144px;
    }
    .tip-line {
      width: 100px;
      height: 1px;
      background: linear-gradient(
        -90deg,
        #4bf5f1,
        #4bf5f1,
        rgba(75, 245, 241, 0.2)
      );
    }
    .tip-point {
      width: 10px;
      height: 10px;
      margin-left: 5px;
      background-color: #4bf5f1;
      transform: rotate(45deg);
      transform-origin: center;
    }
  }
}
</style>
