<template>
  <div class="indi-v">
    <th-icon :name="icon" width="24" height="24" v-if="icon" />
    <span style="color:#ffffff;">{{ label }}</span>
    <br />
    <th-countup class="v-value" :value="value" color="#4bf5f1" :size="24" />
    <span class="unit">{{ unit }}</span>
  </div>
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    value: {
      type: [Number, String],
      default: 0
    },
    unit: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="less" scoped>
.indi-v {
  font-size: 18px;
  color: #ffffff;
  text-align: left;
  .icon {
    margin-right: 5px;
  }
  .unit{
    font-size: 14px;
    margin-left: 5px;
  }
  .v-value {
    font-family: DIN;
  }
}
</style>
